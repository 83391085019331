//Utils folder for common utility functions

export const findIndexOfObjInArr = (Array, selected) => {
    for (let i = 0; i < Array.length; i++) {
        if (Array[i].route === selected) {
            return i; // Return the index when the route matches
        }
    }
    return -1; // Return -1 if the route is not found in the array
}

export const getItemsFromLocalStorage = () => {
    const storedItems = localStorage.getItem('items');

    if (storedItems) {
        return JSON.parse(storedItems);
    } else {
        return [];
    }
}
export const addLocalStorageItem = (items,item) => {

    items.push(item);
    localStorage.setItem('items', JSON.stringify(items));
}

export const removeLocalStorageItem = (items,itemToRemove) => {


    // Filter out the item that needs to be removed
    const updatedItems = items.filter(item => item !== itemToRemove);

    localStorage.setItem('items', JSON.stringify(updatedItems));
}
// isBooked(id){
//     console.log("getItemsFromLocalStorage",getItemsFromLocalStorage())
//     if(getItemsFromLocalStorage().items.indexOf(id)>-1){
//         return true
//     }
//     return false
//
// },
// setBook(id){
//     console.log("99999",id)
//     if(this.isBooked(id)){
//         getItemsFromLocalStorage().removeLocalStorageItem(id)
//     }else{
//         getItemsFromLocalStorage().addLocalStorageItem(id)
//     }
// }
