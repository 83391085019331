<template>
      <popup-modal ref="popup">
            <h2 class="title" style="margin-top: 0; margin-bottom: 10px; text-align:center;">{{ title }}</h2>
            <p class="message">{{ message }}</p>
            <div class="btns">
                  <button class="cancel-btn" @click="_cancel">
                        {{ cancelButton }}
                  </button>
                  <button class="ok-btn" @click="_confirm">
                        {{ okButton }}
                  </button>
            </div>
      </popup-modal>
</template>

<script>
import PopupModal from "./PopupModal.vue";

export default {
      name: "ConfirmDialogue",

      components: { PopupModal },

      data: () => ({
            // Parameters that change depending on the type of dialogue
            title: undefined,
            message: undefined, // Main text content
            okButton: undefined, // Text for confirm button; leave it empty because we don't know what we're using it for
            cancelButton: "No", // text for cancel button

            // Private variables
            resolvePromise: undefined,
            rejectPromise: undefined,
      }),

      methods: {
            show(opts = {}) {
                  this.title = opts.title;
                  this.message = opts.message;
                  this.okButton = opts.okButton;
                  if (opts.cancelButton) {
                        this.cancelButton = opts.cancelButton;
                  }
                  // Once we set our config, we tell the popup modal to open
                  this.$refs.popup.open();
                  // Return promise so the caller can get results
                  return new Promise((resolve, reject) => {
                        this.resolvePromise = resolve;
                        this.rejectPromise = reject;
                  });
            },

            _confirm() {
                  this.$refs.popup.close();
                  this.resolvePromise(true);
            },

            _cancel() {
                  this.$refs.popup.close();
                  this.resolvePromise(false);
                  // Or you can throw an error
                  // this.rejectPromise(new Error('User cancelled the dialogue'))
            },
      },
};
</script>

<style scoped>

.title {
      font-size: 20px;
      border-bottom: 1px solid #ddd;
      padding-bottom: 13px;
}

.message {
      font-size: 17px;
      margin: 20px 0;
      text-align: center;
}
.btns {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
}

.ok-btn , .cancel-btn {
      width: 100px;
      border-radius: 7px;
      border: none;
      padding: 13px 15px;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
}

.cancel-btn {
      background: #f5caca;
      color: red;
}

.ok-btn {
      background: #ccf5d5;
      color: rgb(1, 196, 1);
}
</style>