<template>
  <div class=" w-100">
    <div class="w-100 position-relative">

      <div class="w-100  ">
        <div class="w-100 header  justify-content-between align-items-center d-flex flex-row flex-wrap">
          <div class="left-header d-flex align-items-center">
            <img @click="showMenue()" src="../../assets/images/menu/menu.png"
              class=" menu-icon pointer d-md-none d-block">
            <span :style="{ opacity: (this.$route.path == '/users') && getbackTitle() ? 0.3 : 1 }"
              class="h-100  ml-md-0 ml-4 d-flex align-items-center selected-title">{{ $route.meta.title }}</span>

            <!--      <span v-else :style="{opacity:(this.$route.path=='/compare' ||this.$route.path=='/sales' || this.$route.path=='/worksheets') && getbackTitle()?0.3:1}" class="h-100 ml-md-0 ml-4  align-items-center d-none">Overview</span>-->
            <div v-if="(this.$route.path == '/users') && getbackTitle()" class="d-flex align-items-center">
              <div @click="specialBack" class="next-icon pointer d-flex ml-2 align-items-center justify-content-center">
                <img src="../../assets/images/return-icon.svg">
              </div>
              <div @click="specialBack" class="selected-title ml-2 pointer">{{ $route.meta.title }} </div>
              <!--      <div v-if="1==0" class="selected-title ml-2">{{getbackTitle()}} </div>-->
            </div>

          </div>

          <div class="pic-box d-flex align-items-center justify-content-center">
            <img v-if="avatar" class="profile-pic" :src="avatar">
            <img v-else class="profile-pic" src="../../assets/images/user-default.png">
          </div>
          <!--          <div style="z-index: 9999" v-if="showPopup" class="position-absolute popup ">-->
          <!--            <div class="popup-title w-100 text-center">-->
          <!--&lt;!&ndash;              <span v-if="getPic().first_name">{{getPic().first_name}}</span>&ndash;&gt;-->
          <!--&lt;!&ndash;              <span class="pl-1" v-if="getPic().last_name">{{getPic().last_name}}</span>&ndash;&gt;-->
          <!--            </div>-->
          <!--            &lt;!&ndash;      <div class="popup-sub-title w-100 text-center mt-1">Interactive Brokers IBKR</div>&ndash;&gt;-->
          <!--            <div class="seprator w-100 mt-3"></div>-->
          <!--            <div @click="goToOtherPage('/profile')" class="popup-items mt-4 pointer">-->
          <!--&lt;!&ndash;              <img class="icon" src="../assets/images/menu/Profile.svg"> &ndash;&gt;-->
          <!--              <span class="ml-1">My Profile</span></div>-->
          <!--            &lt;!&ndash;      <div @click="goToOtherPage('/activities')" class="popup-items mt-3 pointer"><img class="icon" src="../assets/images/menu/Allocations-selected.svg"> <span class="ml-3">All Activities</span></div>&ndash;&gt;-->
          <!--            <div class="seprator w-100 mt-4"></div>-->
          <!--            <div @click="logOut" class="popup-footer mt-4 d-flex align-items-center justify-content-center pointer">-->

          <!--&lt;!&ndash;              <img class="icon" src="../assets/images/menu/Logout.svg">&ndash;&gt;-->
          <!--              <span class="ml-3">Log Out</span>-->
          <!--            </div>-->
          <!--          </div>-->


        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
// import store from '@/store';

export default {
  name: 'MainHeader',
  data() {
    return {
      // avatar: (store.state.avatar || localStorage.getItem('avatar')),

      showPopup: false,
    }
  },
  computed: {
    avatar() {
      return (this.$store.state.avatar || localStorage.getItem('avatar')) || ''
    }
  },
  methods: {
    ...mapMutations([

      "setStateMenu",
    ]),
    ...mapGetters([
      "getStateMenu", "getUser"
    ]),
    logOut() {
      this.setParam("Token", null)
      this.goToOtherPage("/login")
    },
    specialBack() {
      this.setbackTitle("")

      this.$root.$emit('hideDetails', false)


    },
    goToOtherPage(path) {
      // alert("kk")
      this.showPopup = false
      this.$router.push({ path: path })
    },
    showMenue() {
      if (!this.getStateMenu()) {
        document.body.classList.add('overflow-hidden')
      } else {
        document.body.classList.remove('overflow-hidden')
      }
      this.setStateMenu(!this.getStateMenu())
    }
  },
}
</script>

<style lang="scss" scoped>
.header {
  padding: 35px 35px 20px 35px;

}

.menu-icon {
  height: changeScreen(40) *1rem;
}

//.pl-menu{
//  padding-left: changeScreen(344) *1rem;
//}
.pic-box {
  height: changeScreen(60) *1rem;
  width: changeScreen(60) *1rem;
  background: rgba(168, 185, 240, 0.3);
  border-radius: changeScreen(18) *1rem;
}

.profile-pic {
  height: changeScreen(48) *1rem;
  width: changeScreen(48) *1rem;
  border-radius: changeScreen(16) *1rem;
  box-shadow: 0 changeScreen(2) *1rem changeScreen(4) *1rem rgba(0, 0, 0, 0.16);
}

.logo {
  height: changeScreen(77) *1rem;
}

.left-header {
  font-family: H1;
  font-size: changeScreen(16) *1rem;
  color: #6B7173;
}

@media screen and (max-width: 768px) {
  //.pl-menu{
  //  padding-left: 0px;
  //}
}

.popup {
  width: changeScreen(248) *1rem;
  border-radius: changeScreen(16) *1rem;
  background: #FFFFFF;
  box-shadow: 0 changeScreen(8) *1rem changeScreen(16) *1rem rgba(34, 96, 171, 0.3);
  padding: changeScreen(20) *1rem;
  top: changeScreen(135) *1rem;
  right: changeScreen(64) *1rem;
}

.popup-title {
  font-family: H1;
  color: #2260AB;
  font-size: changeScreen(16) *1rem;
}

.popup-sub-title {
  font-family: H1;
  color: #6B7173;
  font-size: changeScreen(14) *1rem;
}

.popup-footer {
  color: #E85A15;
  font-family: H1SemiBold;
  font-size: changeScreen(16) *1rem;
}

.popup-items {
  font-family: H1;
  font-size: changeScreen(16) *1rem;
  color: #333333;
}

.icon {
  height: changeScreen(22) *1rem;
}

.seprator {
  height: 1px;
  background: #F2F2F2;
}

.next-icon {
  width: changeScreen(24) *1rem;
  height: changeScreen(24) *1rem;


}

.selected-title {
  font-family: TahomaBold;
  font-size: changeScreen(23) *1rem;
  color: #000000;
}
</style>
